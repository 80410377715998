<template>
    <CSMenuLeft :data="data" />
</template>
  
<script>
    export default {
        name: "CSMenuRight",
        components: {
            CSMenuLeft: () => import('./CSMenuLeft.vue')
        },
        props: {
            data: {
                type: Object,
                default: () => {},
            }
        },
        mounted(){
          console.log(this)
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../libs/common-styles.scss";
    @import "../../styles/main.scss";
    
    ::v-deep {
        .cs-nav-primary-links {
            margin-right: unset !important;
            margin-left: auto;
            flex: 1 0 auto;
            order: 3;
        }

        .cs-interactions {
            margin-left: unset !important;
            margin-right: $default_margin;
            justify-content: unset !important;
        }
    }
</style>